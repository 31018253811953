import React, { useState, useEffect } from "react";
import "./progressBar.css"; // Import your CSS file for styling

const CustomProgressBar = ({ progress }: any) => {
  return (
    <>
      {progress > 0 && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}/>
          {progress !== 100 && (
              <span className="percentage">{`${progress}%`}</span>
            )}
        </div>
      )}
    </>
  );
};

export default CustomProgressBar;
