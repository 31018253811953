export const formValidation =(...args)=>{
    const [newPassword,confirmPassword] = args
    
    let isSame=false 
    if(newPassword === confirmPassword) {
        isSame = true
    }
    return isSame    
    
}
