// Home.tsx

import React, { FC, useCallback, useEffect, useState } from "react";
import "../../App.scss";
import axios from "axios";
import { apiUrlEndPoint, baseUrl } from "../../api/url";
import useModal from "../../utlis/customHooks/useModal";
import Modal from "../../uikit/Modal";
import ViewSection from "../../components/View";
import { StopFunctionality } from "../../components/StopFunctionality";
import ProgressBar from "../../components/ProgressBar";
import { ToastContainer, toast } from 'react-toastify';

interface WebsiteList {
  website: string;
}
export interface File {
  id: number;
  name: string | null;
  created_at: string;
  updated_at: string;
  active: boolean;
  status: string;
  leads: string | number;
  files: string;
  record_count: number;
  progress: number;
  website_list: [WebsiteList] | null;
}

export interface FileListComponentProps {
  filesList: File[];
}

export interface StopFileIds {
  fileId: number
}

const Home: FC = () => {
  const [filesList, setFilesList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [runningFileId, setRunningFileId] = useState<string | number>("");
  const modal1 = useModal();
  const modal2 = useModal();
  const [email, setEmail] = useState<string>("");
  const [fileId, setFileId] = useState<number | string>(0);
  const [downloadingFileLoading, setDownloadFileLoading] =
    useState<boolean>(false);
  const [fileDownloadeRsponse, setFileDownloadResponse] = useState<string>("");
  const [fileDownloadeErrorRsponse, setFileDownloadErroResponse] =
    useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [fileLink, setFileLink] = useState<string>("");
  const [viewfileId, setViewFileID] = useState<number>(0);
  const [stopFileId, setStopFileId] = useState<number | string>(0);
  const [stopApiLoading,setStopApiLoading] = useState(false)
  const [progressId, setProgressId] = useState<number[]>([])
  const [isUpdateInProgress, setUpdateInProgress] = useState(false);


  useEffect(() => {
    if (fileLink) {
      const downloadLink = document.getElementById("download-link");
      downloadLink?.click(); // Automatically trigger the click event on the link
    }
  }, [fileLink]);

  const fetchFileList = useCallback(() => {
    setIsLoading(true);

    const response = axios.post(
      `${apiUrlEndPoint.fetchFileDetailsApi()}?limit=10&offset=${offset}`,
      {
        config: {
          timeout: 10000,
          is_: "get_list",
        },
      }
    );
    response.then((res) => {
      // console.log(res.data.files_list);
      const updatedFileList = res.data.files_list.map((list: File) => ({
        ...list,
        progress: list.status === "completed" ? 100 : 0,
      }));
      setFilesList(updatedFileList);
      setIsLoading(false);
    });
    response.catch((err) => {
      console.log(err);
    });
  }, [offset]);
  useEffect(() => {
    if (offset >= 0) {
      fetchFileList();
    }
  }, [offset, fetchFileList]);
  const stringIntoArray = (list: any) => {
    const unescapedString = list.replace(/\\"/g, '"');
    const arrayValues = JSON.parse(unescapedString);
    console.log(arrayValues);

    return arrayValues.map((item: string) => parseInt(item));
  };

  const fileRunHandler = async (file: File) => {
    setRunningFileId(file.id);
    if (file.website_list && file.website_list?.length > 0) {
      setTimeout(() => {
        setRunningFileId("");
        fetchFileList();
      }, 2000);
      const payload = {
        proccess_is: file.name,
        file_id: file.id,
        file_path: `http://165.22.29.27:8000/media/${file.files}`,
        website_list: stringIntoArray(file.website_list),
      };

      const postFileRunner: any = await axios.post(
        apiUrlEndPoint.runFile(),
        payload
      );
      console.log("postFileRunner", postFileRunner);
      if (postFileRunner.status && postFileRunner?.status == 200) {
        setRunningFileId("");
        // fetchFileList();
      } else {
        console.error("/api/runfile");
        console.log(postFileRunner.data.message);
        setRunningFileId("");
      }
    }
  };

  const formatDate = (dateString: string) => {
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined);
  };

  const downloadFile = async (id: string | number) => {
    setDownloadFileLoading(true);
    setFileDownloadErroResponse("");
    setFileDownloadResponse("");
    axios
      .post(
        `${baseUrl}/api/app/servicesapps/servicesapps_workspace/generate-excel/${id}/`,
        {
          email_addresses: [""],
        }
      )
      .then((res) => {
        setFileId("");
        setDownloadFileLoading(false);
        setFileDownloadResponse(res?.data?.message);
        setFileLink(res.data.download_link);
        const downloadLink = res.data.download_link;
        const fileName = downloadLink.substring(
          downloadLink.lastIndexOf("/") + 1
        );

        downloadFileUsingBlob(downloadLink, fileName);
      })
      .catch((error) => {
        setDownloadFileLoading(false);
        setFileId("");
        setFileDownloadErroResponse("something went wrong");
      });
  };

  const downloadFileUsingBlob = (url: string, fileName: string) => {
    axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
        // Handle download error
      });
  };

  const resetToggle = () => {
    modal1.toggle();
  };

  const stopRestToggle = () => {
    modal2.toggle();
  };

  const stopApiCall = async () => {
    try {
      setStopApiLoading(true)
      const stopCall = await axios.post(apiUrlEndPoint.stopApi(), {
        file_id: stopFileId,
      });
      if (stopCall.status == 200) {
        setStopApiLoading(false)
        fetchFileList();
      }
    } catch (error) {
      console.log(error);
    }
    modal2.toggle();
  };

// Assuming you have a state for tracking whether an update is in progress

const getProgressApiCall = async (id: number) => {
  try {
    setProgressId([id])
    const response = await axios.post(`${apiUrlEndPoint.getProgress()}`, { file_id: id });
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: true, file_id: id };
  }
};

const getProgress = async (id: number) => {

  if (isUpdateInProgress) {
    toast('Updating the progress, Please wait.')
    // console.log('Update in progress. Skipping.');
    //  setProgressId([])
     return;
   
  }

  //new id 

  try {
    setUpdateInProgress(true);

    const progressResult = await getProgressApiCall(id);
    console.log("progressResult", progressResult.file_id);

    if (progressResult.error) {
      console.error('API call failed');
    } else {
      const updatedList = filesList.map((file) => ({
        ...file,
        progress: file.id === progressResult.file_id ? progressResult.progress.toFixed(2) : file.progress,
      }));

      setFilesList(updatedList);
      setProgressId([])
      console.log("Updated list", updatedList);
    }
  } catch (error) {
    console.error('An unexpected error occurred', error);
  } finally {
    setUpdateInProgress(false);
    setProgressId([])
  }
};


  const checkifBatchFile = (file: File) => {
    if (
      file.name?.slice(0, 6).toLowerCase() === "batch" &&
      file.status.toLowerCase() === "completed"
    ) {
      return true;
    } else {
      fileRunHandler(file);
    }
  };

  const fetchDownloadUploadedFile = (id: number) => {
    const payload = {
      file_id: id,
    };

    axios
      .post(apiUrlEndPoint.downloadApi(), payload)
      .then((res) => {
        console.log(res);

        if (res.data?.download_link) {
          downloadUploadFile(res.data.download_link);
        } else {
          console.log(res.data);
          toast(res.data.Info)
        }
        // const downloadLink = res.data.download_link;

      })
      .catch((e) => console.log(e));
  };

  function downloadUploadFile(url: string) {
    const templink = document.createElement("a");
    templink.href = url;
    templink.click();
  }



  return (
    <React.Fragment>
      <ToastContainer />
      <header className="bg-white shadow">
        <div className="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h2 className="font-semibold text-xl text-gray-800 leading-tight">
            Files Processed
          </h2>
        </div>
      </header>
      <main>
        <div className="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {!isLoading ? (
            <div className="p-4 sm:p-8 bg-white shadow sm:rounded-sm">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th scope="col">S.No</th> */}
                    <th scope="col">File Name</th>
                    <th scope="col">File Count</th>
                    <th scope="col">Leads </th>
                    <th scope="col">Status</th>
                    <th scope="col">Date Uploaded</th>
                    <th scope="col">Processed Date</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filesList.map((file) => (
                    <tr key={file.id}>
                      {/* <td scope='row'>{index+1}</td> */}
                      <td className="flex flex-row gap-2 align-middle">
                        <div className="flex flex-col gap-6">
                          <span className="text-base">
                            <span 
                            className='filename'  
                              onClick={() => {
                                
                                fetchDownloadUploadedFile(file.id)
                                
                              } }
                            >
                              {file.name ? file.name : " - "}{" "}
                            </span>
                            {file.status ===
                              ("in_progress" || "inprogress") && (
                                <>
                                  <i
                                    className={`fa fa-refresh mt-2 ml-4 hover:cursor-pointer refresh-icon ${progressId && progressId.find(id => id === file.id) ? 'rotate-icon' : 'no-rotate-icon'}`}
                                    onClick={() => {
                                      getProgress(file.id)

                                    }}
                                  ></i>
                                </>
                              )}
                          </span>
                          {file.status === ("in_progress" || "inprogress") ? (
                            <ProgressBar progress={file.progress} />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>{file.record_count ? file.record_count : "NA"}</td>
                      <td>{file.leads}</td>
                      <td className="file-status">
                        <p className="processing-status">{file.status}</p>
                      </td>
                      <td>{formatDate(file.created_at)}</td>
                      <td>{formatDate(file.updated_at)}</td>
                      <td className="flex align-middle justify-center">
                        {runningFileId === file.id ? (
                          <button>Loading...</button>
                        ) : (
                          <>
                            {file.status.toLowerCase() == "waiting" &&
                              file.name?.slice(0, 5).toLowerCase() !== "batch" ? (
                              <button onClick={() => checkifBatchFile(file)}>
                                Run
                              </button>
                            ) : (
                              <button className="disable">Run</button>
                            )}
                          </>
                        )}
                        {file.status.toLowerCase() !== "waiting" ? (
                          <button
                            onClick={() => {
                              setViewFileID(file.id);
                              resetToggle();
                            }}
                          >
                            View
                          </button>
                        ) : (
                          <button className="disable">View</button>
                        )}
                        {fileId === file.id ? (
                          <button>Loading...</button>
                        ) : (
                          <>
                            {" "}
                            {file.status == "completed" ? (
                              <button
                                onClick={() => {
                                  setFileId(file.id);
                                  downloadFile(file.id);
                                }}
                              >
                                Export
                              </button>
                            ) : (
                              <button className="disable">Export</button>
                            )}
                          </>
                        )}
                        {file.status !== "completed" &&
                          file.status !== "waiting" ? (
                          <button
                            onClick={() => {
                              stopRestToggle();
                              setStopFileId(file.id);
                            }}
                          >
                            Stop
                          </button>
                        ) : (
                          <button className="disable">Stop</button>
                        )}
                      </td>
                    </tr>
                  ))}

                  <tr />
                </tbody>
              </table>
              <a
                id="download-link"
                href={fileLink}
                download="downloaded_file.xlsx"
              ></a>
              <div className="flex justify-end align-middle mt-8">
                {offset >= 10 && (
                  <button
                    onClick={() => {
                      setOffset(offset - 10);
                    }}
                    className="mr-8 bg-buttonbg_color text-white px-4 py-2 text-sm rounded-sm"
                  >
                    Prev
                  </button>
                )}

                <button
                  onClick={() => {
                    setOffset(offset + 10);
                  }}
                  className="bg-buttonbg_color text-white px-4 py-2 text-sm rounded-sm"
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
          <br />
        </div>
        <Modal
          isOpen={modal1.isOpen}
          toggle={resetToggle}
          modalSizeClassName="large"
        >
          <ViewSection fileId={viewfileId} />
        </Modal>
        <Modal
          isOpen={modal2.isOpen}
          toggle={stopRestToggle}
          modalSizeClassName="small"
        >
          <StopFunctionality
            stopApiCallBack={() => stopApiCall()}
            toggleStopModal={() => stopRestToggle()}
            stopApiLoading={stopApiLoading}
          />
        </Modal>
      </main>
    </React.Fragment>
  );
};

export default Home;
