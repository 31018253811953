import React, { FC, useState } from "react";
import InputLable from "../../uikit/InputLable";
import { formValidation } from "../../utlis/formValidations";

export const inputClassName =
  "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full py-2 px-4";
export const labelClassName = "block font-medium text-sm text-gray-700";

const ProfileInfo: FC = () => {
  const [userInfo, setUserInfo] = useState<{
    username: string;
    email: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    username: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "Name") {
      setUserInfo({ ...userInfo, username: value });
    }
    if (name === "Email") {
      setUserInfo({ ...userInfo, email: value });
    }
    if (name === "Current Password") {
      setUserInfo({ ...userInfo, currentPassword: value.toString() });
    }
    if (name === "New Password") {
      setUserInfo({ ...userInfo, newPassword: value.toString() });
    }
    if (name === "Confirm Password") {
      setUserInfo({ ...userInfo, confirmPassword: value.toString() });
    }
  };

  const submitProfileData = (e: React.FormEvent) => {
    e.preventDefault();
    const { confirmPassword, currentPassword, email, newPassword, username } =
      userInfo;
    const result = formValidation(newPassword, confirmPassword);
    if (result) {
      console.log(
        confirmPassword,
        currentPassword,
        email,
        newPassword,
        username
      );
      setPasswordError("");
    } else {
      setPasswordError("password mismatch");
    }
  };
  const { confirmPassword, currentPassword, email, newPassword, username } =
    userInfo;

  return (
    <div className="p-4 sm:p-8 bg-white shadow sm:rounded-lg">
      <div className="max-w-xl">
        <section>
          <header>
            <h2 className="text-lg font-medium text-gray-900">
              Profile Information
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              Update your account's profile information and email address.
            </p>
          </header>

          <form className="mt-6 space-y-6">
            <div className="">
              <InputLable
                value={username}
                labelName="Name"
                onchange={handleInputChange}
                type="text"
                inputClassName={inputClassName}
                inputRequired={true}
                labelClassName={labelClassName}
              />
            </div>
            <div>
              <InputLable
                value={email}
                labelName="Email"
                onchange={handleInputChange}
                type="email"
                inputClassName={inputClassName}
                inputRequired
                labelClassName={labelClassName}
              />
            </div>

            <div>
              <InputLable
                labelName="Current Password"
                onchange={handleInputChange}
                type="password"
                value={currentPassword}
                inputClassName={inputClassName}
                inputRequired
                labelClassName={labelClassName}
              />
            </div>
            <div>
              <InputLable
                labelName="New Password"
                onchange={handleInputChange}
                type="password"
                value={newPassword}
                inputClassName={inputClassName}
                inputRequired
                labelClassName={labelClassName}
              />
            </div>
            <div>
              <InputLable
                labelName="Confirm Password"
                onchange={handleInputChange}
                type="password"
                value={confirmPassword}
                inputClassName={inputClassName}
                inputRequired
                labelClassName={labelClassName}
              />
            </div>
            <p className="text-red">{passwordError}</p>
            <div className="flex items-center gap-4">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 bg-loginbuttooncolor border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 cursor-pointer"
                onClick={(e) => submitProfileData(e)}
              >
                Save
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export const ProfileHeader = () => {
  return (
    <header className="bg-white shadow">
      <div className="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h2 className="font-semibold text-xl text-gray-800 leading-tight">
          Profile
        </h2>
      </div>
    </header>
  );
};

export default ProfileInfo;
