// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
    width: 100%;
    border: 1px solid #ccc;
    overflow: hidden;
    height: 16px; /* Adjust the height as needed */
    position: relative;
    border-radius:8px;
  }
  
  .progress-bar {
    background-color: #4caf50; /* Adjust the background color as needed */
    height: 100%;
    transition: width 0.5s ease;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .percentage {
    color: #000; /* Adjust the text color as needed */
    font-weight: bold;
    width: 100%;
    text-align: center;
    top: -3px;
    position: absolute;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/progressBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,gBAAgB;IAChB,YAAY,EAAE,gCAAgC;IAC9C,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB,EAAE,0CAA0C;IACrE,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW,EAAE,oCAAoC;IACjD,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,kBAAkB;EACpB","sourcesContent":[".progress-container {\n    width: 100%;\n    border: 1px solid #ccc;\n    overflow: hidden;\n    height: 16px; /* Adjust the height as needed */\n    position: relative;\n    border-radius:8px;\n  }\n  \n  .progress-bar {\n    background-color: #4caf50; /* Adjust the background color as needed */\n    height: 100%;\n    transition: width 0.5s ease;\n    position: absolute;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .percentage {\n    color: #000; /* Adjust the text color as needed */\n    font-weight: bold;\n    width: 100%;\n    text-align: center;\n    top: -3px;\n    position: absolute;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
