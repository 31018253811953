import React from "react";

interface StopFunctionalityProps {
  stopApiCallBack: () => void;
  toggleStopModal:()=>void;
  stopApiLoading:boolean
}

export const StopFunctionality = (props: StopFunctionalityProps) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="text-3xl">Alert</div>
      <div className="text-lg">Do you want to stop the execution?</div>
      <div className="flex flex-row justify-end align-middle gap-4">
        <div
          onClick={() => {
            props.stopApiCallBack();
          }}
          className="bg-buttonbg_color text-white px-8 py-2 text-sm rounded-lg cursor-pointer"
        >
        {props.stopApiLoading?"Loading...":"Yes"} 
        </div>
        <button 
        onClick={()=>{
            props.toggleStopModal();
        }}
        className="bg-buttonbg_color text-white rounded-lg  px-8 py-2 text-sm">
          No
        </button>
      </div>
    </div>
  );
};
