// Modal.tsx

import React, { ReactNode } from "react";
import closeIcon from '../images/closebutton.svg'
interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  modalSizeClassName:string
}

export default function Modal(props: ModalType) {
  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay" onClick={props.toggle}>
          <div onClick={(e) => e.stopPropagation()} className={`modal-box ${props.modalSizeClassName}`}>
            <div className="flex justify-end w-full pb-4" onClick={props.toggle}>
              <img src={closeIcon} className="w-4 cursor-pointer"/>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}
