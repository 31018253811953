
import Login from '../../components/login';



const LoginPage = () => {
    return (
        <div className='grid h-screen place-items-center'>
            <Login />
        </div>

    )
}

export default LoginPage