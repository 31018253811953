import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { apiUrlEndPoint } from "../../api/url";
import { WebsiteList } from "../../components/FileUpload/WebsiteList";
import { Website } from "../../utlis/types";

const FileUpload: FC = () => {

  const fileInputRef = useRef<HTMLInputElement|null>(null)

  const [selectedFile, setSelectedFile] = useState<File | null | string>(null);
  const [alias, setAlias] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] =
    useState<string>("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [websiteList, setWebSiteList] = useState<Website[]>([]);
  const [filterList, setFilterList] = useState<Website[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(30);
  const [websiteListError, setWebSiteListError] = useState({});
  const [websiteListLoading, setWebsiteListLoading] = useState(false);
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files?.[0];
    console.log("selected file", selectedFile?.type);

    if (selectedFile) {
      if (selectedFile.type !== "text/csv") {
        setErrorMessageVisible(true);
        setSelectedFile("");
        setSelectedFileName('');
      } else {
        setErrorMessageVisible(false);
        setSelectedFile(selectedFile);
        setSelectedFileName(selectedFile.name);
      }
    } else {
      setSelectedFileName("");
    }
  };
  const fetchWebsiteList = useCallback(() => {
    setWebsiteListLoading(true);
    axios
      .get(
        `${apiUrlEndPoint.getWebsiteDetail()}?limit=${limit}&offset=${offset}`
      )
      .then((res) => {
        const updatedData = res.data.map((list: any) => ({
          ...list,
          is_checked: false,
        }));
        setFilterList((prevState) => [...prevState, ...updatedData]);
        setWebSiteList((prevState) => [...prevState, ...updatedData]);
        setWebsiteListLoading(false);
        console.log(res);
      })
      .catch((e) => {
        setWebsiteListLoading(false);
        setWebSiteListError(e);
      });
  }, [offset]);
  useEffect(() => {
    fetchWebsiteList();
  }, []);
  useEffect(() => {
    if (offset >= 30) {
      fetchWebsiteList();
    }
  }, [offset, fetchWebsiteList]);

  useEffect(()=>{
    console.log("selected Filename",selectedFileName,'selectedfile',selectedFile);
  },[
    selectedFile
  ])

  const handleOffsetValue = () => {
    setOffset(offset + 30);
  };

  const checkWebsiteIsSelected = () => {
    let result = false;
    result = websiteList.some((list) => list.is_checked === true);
    return result;
  };

  const handleFileUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(alias, selectedFile);

    if (!selectedFile) {
      setError("Please select a file to upload.");
      return;
    }

    if (checkWebsiteIsSelected() === false) {
      setError("Please select atleast one website to upload");
      return;
    }

    setError("");
    setSuccessMessage("");
    setIsLoading(true);

    const myHeaders = {
      Accept: "application/json, text/plain, */*",
    };

    const formData = new FormData();
    formData.append("files", selectedFile);
    formData.append("name", alias);

    // formData.append('website_list', '24');

    const filterSelectedWeblist = websiteList.filter(
      (list) => list.is_checked === true
    );
    const getIds = filterSelectedWeblist.map((list) => list._id);

    getIds.forEach((id) => {
      formData.append("website_list", id.toString());
    });

    const url = apiUrlEndPoint.fileUploadApiEndpoint();

    axios
      .post(url, formData, {
        headers: myHeaders,
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          console.log("/api/fileupload", response);

          setSuccessMessage("File Uploaded Successfully");
          setIsLoading(false);
          clearAll()
        } else {
          console.error("/api/fileupload", response);
          setError("Upload failed. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        if (axios.isAxiosError(error)) {
          if (
            error.response?.data?.name ===
            "files validate model with this name already exists."
          ) {
            setError("File name is already used. Please use different name");
          } else {
            setError(error.message);
          }
        }
        setIsLoading(false);
      });
  };

  const handlewebsiteChecked = (id: number, is_checked: boolean) => {
    console.log(id, is_checked);
    const updatedValue = websiteList.map((list) =>
      list._id === id ? { ...list, is_checked: is_checked } : list
    );
    setWebSiteList(updatedValue);
    console.log("checked values", updatedValue);
  };

  const filterContent = (category: string) => {
    setWebSiteListError({});
    console.log("category", category);
    if (category === "All") return setWebSiteList(filterList);
    const filteredValue = filterList.filter(
      (list) => list.category === category
    );
    setWebSiteList(filteredValue);
    console.log("filteredValue", filteredValue);
  };

  const clearAll = () => {
    setSelectedFile("");
    setAlias("");
    setError("");
    setWebSiteList((prevValue) =>
      prevValue.map((list) => ({ ...list, is_checked: false }))
    );
    setSuccessMessage("");
    setSelectedFileName("");
    setErrorMessageVisible(false);
    setWebSiteListError({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  
  return (
    <React.Fragment>
      <div className="h-4/5">
        <div className="bg-white py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="font-semibold text-lg">Upload new file:</h1>
        </div>
        <section className=" my-6 sm:mx-0  md:mx-12 flex flex-wrap flex-col-reverse lg:flex-row justify-between gap-0 align-top">
          {websiteListLoading ? (
            <p>Loading....</p>
          ) : (
            <div className="lg:w-[73%] w-[100%]">
              <WebsiteList
                listToFilter={filterList}
                list={websiteList}
                onChangeInput={handlewebsiteChecked}
                handlefilterList={(value) => filterContent(value)}
                changeOffset={handleOffsetValue}
                websiteListError={websiteListError}
              />
            </div>
          )}
          <form
            className=" py-6 px-4 sm:px-6 lg:px-8 bg-white rounded-md lg:w-[25%] w-[100%] h-[100%]"
            onSubmit={handleFileUpload}
          >
            <div className="flex flex-col mb-4">
              <label className="text-sm ">
                File alias <span className="text-red font-bold">*</span>{" "}
              </label>
              
              <input
                type="text"
                className="border-solid py-1 px-2 my-1 rounded-sm"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                required
              />
              <small className="text-[70%] text-[#74788d]">
                This will be shown instead of the file name.
              </small>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center border border-[#e2e3e3] py-1 rounded-sm">
                <label className="relative cursor-pointer  text-blue-500  hover:text-blue-600   w-full">
                  <span className="bg-[#f0f0f0] py-2 px-4 text-sm">
                    {" "}
                    Choose File{" "}
                    <span className="text-red font-bold">* {"   "}</span>{" "}
                  </span>
                  
                  <input
                    type="file"
                    className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                    accept=".csv"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    // value={selectedFile}
                  />{" "}
                  {selectedFileName}
                </label>
              </div>

              <small className="mt-1">File</small>
            </div>
            <div className="mt-8 flex justify-between gap-4">
              <button
                onClick={() => clearAll()}
                className="bg-buttonbg_color text-white rounded-sm px-4 py-2 capitalize"
              >
                clear
              </button>
              <button
                className="bg-buttonbg_color text-white px-4 py-2 text-sm rounded-sm"
                type="submit"
              >
                {isLoading ? "Loading.." : "Process File"}
              </button>
            </div>
            {error && (
              <p style={{ color: "red" }} className="capitalize mt-2">
                {error}
              </p>
            )}
            {successMessage && (
              <p style={{ color: "green" }} className="capitalize mt-2">
                {successMessage}
              </p>
            )}
            {errorMessageVisible && (
              <p style={{ color: "red" }}>
                Only CSV file is allowed to upload.
              </p>
            )}
          </form>
        </section>
      </div>
    </React.Fragment>
  );
};

export default FileUpload;
