import React, { ChangeEvent, FC, useLayoutEffect } from "react";
import { Website } from "../../utlis/types";

interface WebsiteListProps {
  list: Website[];
  listToFilter: Website[];
  onChangeInput: (id: number, is_checked: boolean) => void;
  handlefilterList: (value: string) => void;
  changeOffset: () => void;
  websiteListError:object
}

export const WebsiteList: FC<WebsiteListProps> = ({
  list,
  onChangeInput,
  handlefilterList,
  listToFilter,
  changeOffset,
  websiteListError
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target);
    const { id, value } = event.target;
    onChangeInput(parseInt(id), Boolean(value));
  };

  const filterweblistCategory = () => {
    const uniqueCategories = new Set();

    const uniqueData = listToFilter.filter((item) => {
      const isCategoryUnique = !uniqueCategories.has(item.category);
      uniqueCategories.add(item.category);
      return isCategoryUnique;
    });
   
    return (
      <>
        <select
          className="border-solid border px-2 rounded-sm mx-1 text-sm cursor-pointer"
          name="selectedFruit"
          onChange={(e) => handlefilterList(e.target.value)}
          defaultValue={"All"}
        >
          <option value={"All"}>All</option>
          {uniqueData
            .filter((weblist) => weblist.category !== null)
            .map((weblist) => (
              <option
                key={weblist._id}
                value={weblist.category}
                className="m-2 cursor-pointer"
              >
                {weblist.category}
              </option>
            ))}
        </select>
      </>
    );
  };
 
  return (
    <React.Fragment>
      <div className=" bg-white py-6 px-4 sm:px-6 lg:px-8 ">
        {/* {listToFilter.filter((list) => list.category !== null).length > 0 && ( */}
        <section className="flex justify-end align-middle">
          <label className="text-sm">Select by Category:</label>
          {filterweblistCategory()}
        </section>
        {/* )} */}

        <section className="mt-6 grid grid-cols-4 gap-8 overflow-y-auto max-h-[80vh] rounded-lg ">
          {list.map((website, index) => (
            <>
              <div key={index} className="flex gap-2">
                {/* <p className="text-sm">{website.category}</p> */}
                <input
                  type="checkbox"
                  id={website._id.toString()}
                  onChange={handleChange}
                  value={website.is_checked.toString()}
                  className="w-md"
                  checked={website.is_checked}
                />
                <label className="text-md text-black">{website.website}</label>
              </div>
            </>
          ))}
        </section>
        {Object.keys(websiteListError).length>0?<p style={{ color: "green" }} className="capitalize text-center mt-6 mb-2"> You have reached the End of List</p>:
        (list.length>=30?
          <div className="flex justify-end my-2">
          <button
            onClick={() => changeOffset()}
            className="bg-buttonbg_color text-white px-6 py-2 text-sm rounded-sm"
          >
            More
          </button>
        </div>:''
        )
        }
      </div>
    </React.Fragment>
  );
};
