import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { apiUrlEndPoint } from "../../api/url";

interface viewRecordData {
  website_name: string;
  category: string;
  found_count: string | number;
}

interface ViewSectionProps {
  fileId: number;
}

const ViewSection = (props: ViewSectionProps) => {
  const [viewRecordData, setViewRecordData] = useState<viewRecordData[]>([]);
  const [error, setError] = useState<string>("");
  const [isloading, setIsLoading] = useState<boolean>(true);
  const [offset,setOffset] = useState<number>(0)
 
  useEffect(() => {
    fetchViewRecordData()
  }, [offset]);

  const fetchViewRecordData = () => {
    
    axios
      .post(
        apiUrlEndPoint.getWebsitesForFile(),
        {
          file_id: props.fileId,
          limit: 10,
          offset: offset,
        }
      )
      .then((res) => {
        setIsLoading(false);
        setViewRecordData([...viewRecordData,...res.data]);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message == "Request failed with status code 404") {
          setError("list is not found");
        } else {
          setError(error.message);
        }
      });
  }


  return (
    <>
      {isloading ? (
        <>loading...</>
      ) :viewRecordData.length === 0&& error ? (
        <p className="text-red text-base capitalize">{error}</p>
      ) : (
        <>
        <table className="px-6">
          <thead>
            <th className="text-base capitalize ">category</th>
            <th className="text-base capitalize ">Website Name</th>
            <th className="text-base capitalize ">count</th>
          </thead>
          <tbody>
            {/* {viewRecordData.map((list) =><>{list.website_name}</> )} */}
            {viewRecordData.map((data) => (
              <tr className="">
                <td className="text-base">
                  {data.category ? data.category : "-"}
                </td>
                <td className="text-base">{data.website_name}</td>
                <td className="text-base">{data.found_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {viewRecordData.length>0 && error.length==0 ?  
          <div className="flex justify-end align-middle mt-6">
            <button className="bg-buttonbg_color text-white px-4 py-2 text-sm rounded-sm"
            onClick={()=>setOffset(offset+10)}
            >
              more
            </button>
          </div> :<p style={{ color: "green" }} className="capitalize text-center mt-6 mb-2"> You have reached the End of List</p>}
    </>
      )}
    </>
  );
};

export default ViewSection;
