export const baseUrl = "https://buyersscoop.net";

export const apiUrlEndPoint = {
  fileUploadApiEndpoint: () => {
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/validator-model/files/upload`;
  },
  fetchFileDetailsApi: () => {
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/validator-model/files/management`;
  },
  runFile: () => {
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/validator-model/runner-validate-model`;
  },
  downloadfile:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/generate-excel`
  },
  getProgress:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/api/get_progress/`
  },
  getWebsiteDetail:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/websites/`
  },
  login:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/api/login/`
  },
  getWebsitesForFile:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/get_websites_for_file/`
  },
  downloadApi:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/download-file/`
  },
  stopApi:()=>{
    return `${baseUrl}/api/app/servicesapps/servicesapps_workspace/api/stop-execution/`
  }
};

//http://127.0.0.1:8000/
